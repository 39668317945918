export const learnJSData = {
  section: 'Variables',
  items: [
    {
      title: 'Variables - var',
      value: `Using "var" declares a globally-scoped variable that can be reassigned. Variables declared with "var" are not block-scoped. Code blocks are statements/code between curly brackets { }.`,
      link: '/infobook/javascript',
      linkHelper: 'Variables',
    },
    {
      title: 'Variables - let',
      value: `Using "let" declares a blocked-scoped variable that can be reassigned.`,
      link: '/infobook/javascript',
      linkHelper: 'Variables',
    },
    {
      title: 'Variables - const',
      value: `Using "const" declares a blocked-scoped variable that can NOT can be reassigned.`,
      link: '/infobook/javascript',
      linkHelper: 'Variables',
    },
    {
      title: 'Declaring Functions',
      values: [
        `"var" is used to declare a function-scoped or globally-scoped variable, depending on where it is declared. Unlike let and const, variables declared with var are not block-scoped (blocks are statements between { }).`,
        `"let" is used to declare a variable that you expect to reassign and that is confined to a specific block.`,
        `"const" is used to declare variables that you do not expect to reassign`,
      ],
      link: '/infobook/javascript',
      linkHelper: 'Learn',
    },
  ],
};
