import React from 'react';

import Tab from './Tab';

import './index.scss';

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className="tabs-container">
      <div className="tabs-buttons">
        {tabs?.map((tab) => (
          <Tab
            tab={tab}
            key={tab?.key}
            activeTab={activeTab}
            handleClick={setActiveTab}
          />
        ))}
      </div>
      <div className="tabs-content">
        {tabs?.map(
          (tab, index) =>
            activeTab === tab.text && <div key={index}>{tab.content}</div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
