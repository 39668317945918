import React from 'react';

const HeaderLogo = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 381 381"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M190.5 47.625L317.5 119.062V261.938L190.5 333.375L63.5 261.938V119.062L190.5 47.625Z"
      stroke="#26E07F"
      strokeWidth="12.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M190.5 190.5L317.5 119.062"
      stroke="#26E07F"
      strokeWidth="12.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M190.5 190.5V333.375"
      stroke="#26E07F"
      strokeWidth="12.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M190.5 190.5L63.5 119.062"
      stroke="#26E07F"
      strokeWidth="12.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="178.39"
      y="188.208"
      width="22.9518"
      height="113.229"
      fill="white"
    />
    <rect
      x="201.345"
      y="182.715"
      width="23.0037"
      height="113.645"
      transform="rotate(119 201.345 182.715)"
      fill="white"
    />
    <rect
      x="277.835"
      y="127.009"
      width="23.1304"
      height="113.229"
      transform="rotate(60.7274 277.835 127.009)"
      fill="white"
    />
  </svg>
);

export default HeaderLogo;
