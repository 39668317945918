export const releaseNoteData = {
  section: 'Release Notes',
  items: [
    {
      version: '1.0.0',
      date: '3/1/2022',
      title: 'TechTutz Initial Release',
      updates:
        'A passion project built in React, Express, and MongoDB. An idea initially brought to light by Kayden Hardcastle. The HTML sections and the basic account creation are the available features in the initial release.',
    },
    {
      version: '1.0.1',
      title: 'Performance Enhancements Release',
      date: '3/11/2022',
      updates:
        'Change syntax "About Us" to "Contact Us". Removal of assets and other files no longer in use for improved load times. Alerts update - alerts now stick to the top of the page like the Navigation Bar so that no matter how far down on the page you are, you can always see notifications. Release Notes UI update.',
    },
    {
      version: '1.0.2',
      title: 'UI Enhancements Release',
      date: '3/13/2022',
      updates:
        'FIXED: Changed "about us" button link on Landing page - it was previously directing to "about-us" and is now corrected to direct to "contact-us". Updated Create Account page to reflect that we DO NOT SELL OR SHARE YOUR INFORMATION. Lazy load components for improved performance. UI changes on the Release Notes section.',
    },
    {
      version: '1.1.0',
      title: 'Resources Section Release',
      date: '3/23/2022',
      updates:
        'Resources Section is now live. Resources will be continuously updated with the current best resources for web development. Performance enhancement.',
    },
    {
      version: '1.1.1',
      title: 'Contact Us Section Redesign',
      date: '4/02/2022',
      updates:
        'Contact Us Section now has a new UI. Contact information and link to account creation are still present.',
    },
    {
      version: '1.2.0',
      title: 'CSS Section Release',
      date: '6/15/2022',
      updates:
        'CSS Section is now live in Infobook. CSS Learn shows you how to read and write the basics of CSS.',
    },
    {
      version: '1.2.1',
      title: 'UI and CSS Updates',
      date: '7/3/2022',
      updates:
        'Sites content now fills more of the screen for larger layouts. Minor updates to CSS sections.',
    },
    {
      version: '1.4.0',
      title: 'Quick CSS',
      date: '8/05/2022',
      updates:
        'Introduction of the Quick CSS section. This section is one for quickly accessing common solutions needed with CSS.',
    },
    {
      version: '1.4.1',
      title: 'Notifications Update',
      date: '8/13/2022',
      updates:
        'Notifications update. Now copying code will now show a pop up alert for you at the top of the screen.',
    },
    {
      version: '1.4.2',
      title: 'Quick CSS Update',
      date: '9/3/2022',
      updates:
        'Quick CSS update. Additions to the Quick CSS section: Box Shadows and Border Radius.',
    },
    {
      version: '1.4.3',
      title: 'Performance Update',
      date: '11/23/2022',
      updates:
        'A very minor performance update by removing unused dependencies in the app.',
    },
    {
      version: '1.4.4',
      title: 'Version Notice',
      date: '2/19/2023',
      updates:
        'Popup modal on first visit to explain upcoming website changes in 2.0 release.',
    },
    {
      version: '2.0.0',
      title: 'TechTutz rewrite.',
      date: '6/18/2023',
      updates:
        'Increases performance, removes ads, changes UI. Moved to AWS hosting services.',
    },
    {
      version: '2.1.0',
      title: 'Responsive rewrite.',
      date: '6/20/2023',
      updates: 'UI is now fully responsive.',
    },
    {
      version: '2.2.0',
      title: 'CSS Selectors section.',
      date: '6/24/2023',
      updates: 'The CSS section for Selectors is now live.',
    },
    {
      version: '2.2.2',
      title: 'UI Updates.',
      date: '6/26/2023',
      updates:
        'Better responsiveness on very small and very large screens.',
    },
    {
      version: '2.4.0',
      title: 'JavaScript section.',
      date: '11/14/2024',
      updates:
        'Adds initial info and navigation for the JavaScript section.',
    },
    {
      version: '2.4.1',
      title: 'Code display component update.',
      date: '11/15/2024',
      updates:
        'Updates new code snippets (react-syntax-highlighter) to properly copy code it displays',
    },
  ].reverse(),
};
