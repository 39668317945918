const BigSection = () => (
  <div className="big-section">
    <h1>
      From code <span className="zero">zero</span> ...
      <br /> to code <span className="hero">hero</span>!
    </h1>
  </div>
);

export default BigSection;
