import React from 'react';

import { learnJSData } from 'data/javascript/variables';

import DescriptionEntry from 'components/description-entry';

const LetExample = () => {
  const item = learnJSData?.items.find(
    (item) => item.title === 'Variables - let'
  );

  return <DescriptionEntry entry={item} />;
};

export default LetExample;
