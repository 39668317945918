import React from 'react';

import HeaderText from 'components/header-text';

import Example from './Example';
import FileNaming from './FileNaming';
import CodeExample from './CodeExample';
import JavaScriptFlow from './JavaScriptFlow';
import WorkSpaceSetup from './WorkSpaceSetup';

import '../index.scss';

const sampleCode = `
const currentDateTime = new Date();
console.log("The date and time is: " + currentDateTime.toString());
`;

const LearnTab = () => (
  <div className="tab-view">
    <HeaderText
      fontSize="30px"
      fontWeight="bold"
      margin="0 0 40px 0"
      text="Learn JavaScript"
    />
    <div className="inner-tab-content">
      <WorkSpaceSetup />
      <FileNaming />
      <JavaScriptFlow />
      <Example />
      <CodeExample codeString={sampleCode} />
    </div>
  </div>
);

export default LearnTab;
