import React, { useEffect, useContext } from 'react';

import GlobalContext from 'GlobalContext';

import Tabs from 'components/tabs';
import Card from 'components/card';
import BackIcon from 'components/back-arrow-icon';

import AboutView from './tabs/about';
import LearnView from './tabs/learn';
import VariablesTab from './tabs/variables';

import './tabs/index.scss';

const tabs = [
  {
    key: 0,
    index: 0,
    text: 'About',
    content: <AboutView />,
  },
  {
    key: 1,
    index: 1,
    text: 'Learn',
    content: <LearnView />,
  },
  {
    key: 2,
    index: 2,
    text: 'Variables',
    content: <VariablesTab />,
  },
];

const InfobookJavaScriptView = () => {
  const { jsViewTab, setJsViewTab } = useContext(GlobalContext);

  const updateJsTab = (tab) => {
    localStorage.setItem('jsTab', tab);
    setJsViewTab(tab);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem('jsTab');

    if (savedTab) {
      setJsViewTab(savedTab);
    }
  }, []);

  return (
    <Card type="static" headerText="JavaScript" icon={<BackIcon />}>
      <Tabs tabs={tabs} activeTab={jsViewTab} setActiveTab={updateJsTab} />
    </Card>
  );
};

export default InfobookJavaScriptView;
