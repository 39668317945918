import React, { useContext } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

import GlobalContext from 'GlobalContext';

import IconComponent from 'components/icon';

import './CodeExample.scss';

const CodeExample = ({ codeString, language = 'javascript' }) => {
  const {
    setShowNotification,
    setNotificationText,
    setNotificationStatus,
  } = useContext(GlobalContext);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(codeString);
    setNotificationText('Code copied to clipboard!');
    setNotificationStatus('success');
    setShowNotification(true);
  };

  return (
    <div className="code-example-container">
      <SyntaxHighlighter
        showLineNumbers
        language={language}
        style={vscDarkPlus}
      >
        {codeString}
      </SyntaxHighlighter>
      <div className="code-example-copy" onClick={copyToClipboard}>
        <p>Copy Code</p>
        <IconComponent iconName="IoClipboardOutline" size="30px" />
      </div>
    </div>
  );
};

export default CodeExample;
