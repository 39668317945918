import React from 'react';

import { learnJSData } from 'data/javascript/learn';

import DescriptionEntry from 'components/description-entry';

const FileNaming = () => {
  const item = learnJSData?.items.find(
    (item) => item.title === 'File Naming'
  );

  return <DescriptionEntry entry={item} />;
};

export default FileNaming;
