export const aboutData = {
  section: 'About',
  items: [
    {
      title: 'JavaScript',
      value:
        'JavaScript is a versatile and widely-used programming language primarily known for its role in web development. It enables dynamic content on web pages, such as interactive forms, animations, and complex user interfaces. JavaScript can be executed not only in browsers but also on servers using environments like Node.js. Its syntax is easy to learn for beginners, yet it offers powerful features like asynchronous programming, closures, and prototype-based object orientation, making it suitable for a wide range of applications from simple scripts to complex web applications.',
      link: '/infobook/javascript',
      linkHelper: 'About',
    },
    {
      title: 'Variables',
      value:
        "JavaScript uses variables to store data values. Variables can be declared using 'var', 'let', or 'const'.",
      link: '/infobook/javascript',
      linkHelper: 'About',
    },
    {
      title: 'Data Types',
      value:
        'JavaScript supports various data types including undefined, null, boolean, string, symbol, number, and bigint. Note that everything in JavaScript is an Object - allowing for many native data types.',
      link: '/infobook/javascript',
      linkHelper: 'About',
    },
    {
      title: 'Arrays',
      value:
        'Arrays in JavaScript are used to store multiple values in a single variable.',
      link: '/infobook/javascript',
      linkHelper: 'About',
    },
    {
      title: 'Functions',
      value:
        'A function in JavaScript is a block of code designed to perform a particular task. Functions can take input, process it, and return an output.',
      link: '/infobook/javascript',
      linkHelper: 'About',
    },
    {
      title: 'Control Structures',
      value:
        'This includes if-else statements, switch cases, and loops (for, for-in, for-of, while, and do-while)',
      link: '/infobook/javascript',
      linkHelper: 'About',
    },
    {
      title: 'Events',
      value:
        'JavaScript can respond to user actions such as clicks, form submissions, and mouse movements through event handling.',
      link: '/infobook/javascript',
      linkHelper: 'About',
    },
    {
      title: 'Event Loop',
      value:
        'JavaScript has a unique event loop and concurrency model, enabling complex operations like AJAX requests and timers to be handled efficiently',
      link: '/infobook/javascript',
      linkHelper: 'About',
    },
    {
      title: 'DOM Manipulation',
      value:
        'JavaScript can manipulate the Document Object Model (DOM), allowing for dynamic changes to the content, structure, and style of web pages.',
      link: '/infobook/javascript',
      linkHelper: 'About',
    },
    {
      title: 'Error Handling',
      value:
        'JavaScript can respond to user actions such as clicks, form submissions, and mouse movements through event handling.',
      link: '/infobook/javascript',
      linkHelper: 'About',
    },
    {
      title: 'Asynchronous Programming',
      value: `Features like callbacks, promises, and async/await allow for asynchronous programming. Asynchronous programming handles tasks that take time to complete, such as fetching data, reading files, or waiting for a timer. Instead of making the program wait for each task to finish, asynchronous programming allows other parts of the program to keep running. Once the task is done, it "calls back" to the program to let it know it's complete.`,
      link: '/infobook/javascript',
      linkHelper: 'About',
    },
  ],
};
